<template>
	<div class="row">
      <div class="col-lg-6 col-xl-4" v-if="preview.join != null">
         <div class="card mb-2">
            <div class="card-body p-3">
               <div class="row m-0 align-items-center">
                  <div class="col">
                     <h6 class="limitador mt-0 mb-2">{{ $t("welcomeGoodbye.joinMessage.title") }}</h6>
                     <h6 class="limitador my-0 weight-400 font-14 cursor-pointer" title="Edit" @click="editChannelJoin">
                        <span>{{ $t("welcomeGoodbye.joinMessage.desc") }} <span class="ml-1" v-if="serverS.channelWelcomeName != null">#{{ serverS.channelWelcomeName }}</span>
                        <span class="ml-1 text-danger" v-else>#none</span><i class="far fa-pen font-12 ml-3"></i></span>
                     </h6> 
                  </div>
                  <div class="w-max-content">
                     <label class="switch">
                        <input type="checkbox" v-model="preview.join.enabled" @click="toggleMessage('join')"><span class="slider"></span>
                     </label>
                  </div>
                  <div class="w-max-content pl-1" v-if="preview.join.enabled">
                     <button class="btn btn-outline-primary h-auto rounded-sm" @click="testMessage(preview.join.tipo, serverS.channelWelcome)"><i class="far fa-share font-11 mr-1"></i> {{ $t("serverSettings.buttons.test") }}</button>
                  </div>
                  <div class="w-max-content pl-1">
                     <button class="btn btn-primary h-auto rounded-sm" @click="editPreview(preview.join)"><i class="far fa-pen font-11 mr-1"></i> {{ $t("serverSettings.buttons.edit") }}</button>
                  </div>
               </div>
               <div class="row m-0 align-items-center mt-3" v-if="serverS.channelWelcomeName == null && preview.join.enabled">
                  <div class="col">
                     <div class="alert alert-danger alert-dismissible mb-0" role="alert">
                        <button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button>
                        <i class="fa fa-times-circle"></i> {{ $t("welcomeGoodbye.joinMessage.error") }}
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <PreviewMessageDeck class="m-0 p-0" v-show="preview.join.enabled" :message="preview.join" :dadosUsuario="dadosUsuario" />
      </div>

      <div class="col-lg-6 col-xl-4" v-if="preview.exit != null">
         <div class="card mb-2">
            <div class="card-body p-3">
               <div class="row m-0 align-items-center">
                  <div class="col">
                     <h6 class="limitador mt-0 mb-2">{{ $t("welcomeGoodbye.exitMessage.title") }}</h6>
                     <h6 class="limitador my-0 weight-400 font-14 cursor-pointer" title="Edit" @click="editChannelExit">
                        <span>{{ $t("welcomeGoodbye.exitMessage.desc") }} <span class="ml-1" v-if="serverS.channelExitName != null">#{{ serverS.channelExitName }}</span>
                        <span class="ml-1 text-danger" v-else>#none</span><i class="far fa-pen font-12 ml-3"></i></span>
                     </h6>
                  </div>
                  <div class="w-max-content">
                     <label class="switch">
                        <input type="checkbox" v-model="preview.exit.enabled" @click="toggleMessage('exit')"><span class="slider"></span>
                     </label>
                  </div>
                  <div class="w-max-content pl-1" v-if="preview.exit.enabled">
                     <button class="btn btn-outline-primary h-auto rounded-sm" @click="testMessage(preview.exit.tipo, serverS.channelExit)"><i class="far fa-share font-11 mr-1"></i> {{ $t("serverSettings.buttons.test") }}</button>
                  </div>
                  <div class="w-max-content pl-1">
                     <button class="btn btn-primary h-auto rounded-sm" @click="editPreview(preview.exit)"><i class="far fa-pen font-11 mr-1"></i> {{ $t("serverSettings.buttons.edit") }}</button>
                  </div>
               </div>
               <div class="row m-0 align-items-center mt-3"  v-if="serverS.channelExitName == null && preview.exit.enabled">
                  <div class="col">
                     <div class="alert alert-danger alert-dismissible mb-0" role="alert">
                        <button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button>
                        <i class="fa fa-times-circle"></i> {{ $t("welcomeGoodbye.exitMessage.error") }}.
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <PreviewMessageDeck class="m-0 p-0" v-show="preview.exit.enabled" :message="preview.exit" :dadosUsuario="dadosUsuario" />
      </div>

      <div class="col-lg-6 col-xl-4" v-if="preview.private != null">
         <div class="card mb-2">
            <div class="card-body p-3">
               <div class="row m-0 align-items-center">
                  <div class="col" v-if="serverS.donatePack != 'Free'">
                     <h6 class="limitador my-0">{{ $t("welcomeGoodbye.privateMessage.title") }}</h6>
                  </div>
                  <div class="col" v-else>
                     <h6 class="limitador mt-0 mb-2">{{ $t("welcomeGoodbye.privateMessage.title") }}</h6>
                     <h6 class="limitador my-0 weight-400 font-14">
                        <router-link to="/Premium"><i class="far fa-star color-theme font-13 mr-2"></i>Ultimate<small class="mx-2">|</small>{{ $t("vipMessage") }}</router-link>
                     </h6>
                  </div>
                  <div class="w-max-content" v-if="serverS.donatePack != 'Free'">
                     <label class="switch">
                        <input type="checkbox" v-model="preview.private.enabled" @click="toggleMessage('private')"><span class="slider"></span>
                     </label>
                  </div>
                  <div class="w-max-content pl-1" v-if="serverS.donatePack != 'Free'">
                     <button class="btn btn-primary h-auto rounded-sm" @click="editPreview(preview.private)"><i class="far fa-pen font-11 mr-1"></i> {{ $t("serverSettings.buttons.edit") }}</button>
                  </div>
               </div>
            </div>
         </div>
         <PreviewMessageDeck class="m-0 p-0" v-show="preview.private.enabled" :message="preview.private" :dadosUsuario="dadosUsuario" />
      </div>

      <!-- modalChannel -->
      <div class="modal fade" id="modalChannel" tabindex="-1" aria-labelledby="modalChannelLabel" aria-hidden="true">
         <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
               <div class="modal-header">
                  <h5 class="modal-title h4" id="modalChannelLabel">{{ $t("serverSettings.selectChannel.title") }}</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
               </div>
               <div class="modal-body">
                  <div class="row m-0">
                     <div class="col-12 px-1 mb-2">
                        <div class="card mb-0">
                           <div class="card-body">
                              <div class="col-12 px-1 mb-1">
                                 <h6 class="text-center mb-0">{{ $t("serverSettings.selectChannel.sub") }}</h6>
                                 <hr>
                              </div>
                              <div class="col-12 px-1 mb-1">
                                 <div class="cards" v-if="parameters.type == 'JOIN'">
                                    <channel v-for="(channel, index) in channelsList" :key="index" :channel="channel" :selected="parameters.idChannel" @selectChannel="saveChannelJoin($event)" />
                                 </div>
                                 <div class="cards" v-else-if="parameters.type == 'EXIT'">
                                    <channel v-for="(channel, index) in channelsList" :key="index" :channel="channel" :selected="parameters.idChannel" @selectChannel="saveChannelExit($event)" />
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="modal-footer">
                  <button type="button" class="btn btn-round btn-secondary" data-dismiss="modal">{{ $t("serverSettings.selectChannel.title") }}</button>
               </div>
            </div>
         </div>
      </div>

      <!-- modalPreview -->
      <div class="modal fade" id="modalPreview" tabindex="-1" aria-labelledby="modalPreviewLabel" aria-hidden="true">
         <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content h-100">
               <div class="modal-header">
                  <h5 class="modal-title h4" id="modalPreviewLabel">{{ $t("serverSettings.editMessage.title") }}</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
               </div>
               <div class="modal-body">
                  <div class="row m-0" v-if="message != null">
                     <div class="class_preview">
                        <PreviewMessageDeck :message="message" :dadosUsuario="dadosUsuario" class="class_preview_div" />
                     </div>
                     <div class="class_message">
                        <MessageDeck :message="message" @attObjetoMessage="attObjetoMessage($event)" />
                     </div>
                  </div>
               </div>
               <div class="modal-footer">
                  <button type="button" class="btn btn-round btn-primary" @click="savePreview">{{ $t("serverSettings.editMessage.buttons.save") }}</button>
                  <button type="button" class="btn btn-round btn-secondary" data-dismiss="modal">{{ $t("serverSettings.editMessage.buttons.close") }}</button>
               </div>
            </div>
         </div>
      </div>
	</div>
</template>

<script>

import $ from 'jquery'
import { mapState } from 'vuex'
import MessageDeck from '@/components/MessageDeck.vue'
import channel from '@/components/whitelist/Channel.vue'
import PreviewMessageDeck from '@/components/PreviewMessageDeck.vue'

export default {
	name: 'Welcome_&_Goodbye',
	data: function () {
		return {
			message: null,
         parameters: {'idServer': null, 'idChannel': null, 'nameChannel': null, 'type': null},
			preview: {'join': null, 'exit': null, 'private': null}
		}
	},
	computed: {
		...mapState({
			dadosUsuario: state => state.dadosUsuario,
			channelsList: state => state.channelsList,
			serverS: state => state.serverS,
			urlRest: state => state.urlRest,
		}),
	},
	components: {
		MessageDeck, PreviewMessageDeck, channel
	},
	methods: {
		getSettings : function () {
         this.$store.dispatch('alternarTelaCarregamento', true)

         this.$axios({
            method: 'get',
            url: this.urlRest +'servicos/getsettingsserver',
            params: {
               idServer: this.serverS.id_s
            }
         }).then(response => {
            this.preview = response.data;

         }).catch((error) => {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  this.$store.dispatch('deslogar')

                  this.$toast.fire({
                     icon: 'warning',
                     title: this.$t("serverSettings.swal.expired")
                  });
               } else {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Error: ' + error.response.status
                  });
               }
            } else {
               this.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         });
      },
      editChannelJoin : function () {
         this.parameters = {
            'idServer': this.serverS.id_s,
            'idChannel': String(this.serverS.channelWelcome),
            'nameChannel': this.serverS.channelWelcomeName,
            'type': 'JOIN'
         }

         $('#modalChannel').modal('show')
      },
      editChannelExit : function () {
         this.parameters = {
            'idServer': this.serverS.id_s,
            'idChannel': String(this.serverS.channelExit),
            'nameChannel': this.serverS.channelExitName,
            'type': 'EXIT'
         }

         $('#modalChannel').modal('show')
      },
      saveChannelJoin : function (channel) {
         this.$store.dispatch('alternarTelaCarregamento', true)
         this.parameters.idChannel = channel.id;
         this.parameters.nameChannel = channel.name;

         this.$axios({
            method: 'post',
            url: this.urlRest +'servicos/alterchannelwelcome',
            headers: {
					'Content-Type': 'application/json'
            },
				data: this.parameters

         }).then(() => {
            $('#modalChannel').modal('hide')

            let attServer = this.serverS;
            attServer.channelWelcome = this.parameters.idChannel;
            attServer.channelWelcomeName = this.parameters.nameChannel;
            this.$store.dispatch('updateServerS', attServer)

            this.$toast.fire({
               icon: 'success',
               title: this.$t('serverSettings.swal.saved')
            })
         }).catch((error) => {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  this.$store.dispatch('deslogar')

                  this.$toast.fire({
                     icon: 'warning',
                     title: this.$t("serverSettings.swal.expired")
                  });
               } else {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Error: ' + error.response.status
                  });
               }
            } else {
               this.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         });
      },
      saveChannelExit : function (channel) {
         this.$store.dispatch('alternarTelaCarregamento', true)
         this.parameters.idChannel = channel.id;
         this.parameters.nameChannel = channel.name;

         this.$axios({
            method: 'post',
            url: this.urlRest +'servicos/alterchannelexit',
            headers: {
					'Content-Type': 'application/json'
            },
				data: this.parameters

         }).then(() => {
            $('#modalChannel').modal('hide')

            let attServer = this.serverS;
            attServer.channelExit = this.parameters.idChannel;
            attServer.channelExitName = this.parameters.nameChannel;
            this.$store.dispatch('updateServerS', attServer)

            this.$toast.fire({
               icon: 'success',
               title: this.$t('serverSettings.swal.saved')
            })
         }).catch((error) => {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  this.$store.dispatch('deslogar')

                  this.$toast.fire({
                     icon: 'warning',
                     title: this.$t("serverSettings.swal.expired")
                  });
               } else {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Error: ' + error.response.status
                  });
               }
            } else {
               this.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         });
      },
		editPreview : function (preview) {
         this.message = null;
         
         setTimeout(() => {
            this.message = JSON.parse(JSON.stringify(preview));
            $('#modalPreview').modal('show')
         }, 50);
      },
		attObjetoMessage: function(e) {
			this.message = e;
		},
      savePreview : function () {
         this.$store.dispatch('saveMessage', this.message).then(() => {
            $('#modalPreview').modal('hide')

				this.getSettings()
            this.message = null;

            this.$toast.fire({
               icon: 'success',
               title: this.$t('serverSettings.swal.message')
            })
         }).catch((error) => {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  this.$store.dispatch('deslogar')

                  this.$toast.fire({
                     icon: 'warning',
                     title: this.$t("serverSettings.swal.expired")
                  });
               } else {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Error: ' + error.response.status
                  });
               }
            } else {
               this.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         })
      },
      toggleMessage : function (type) {
         let value = null;

         setTimeout(() => {
            switch (type) {
               case "join":
                  value = this.preview.join.enabled
               break;
               case "exit":
                  value = this.preview.exit.enabled
               break;
               case "private":
                  value = this.preview.private.enabled
               break;
            }

            this.$store.dispatch('toggleMessage', {type: type, value: value}).then(() => {
               this.$toast.fire({
                  icon: 'success',
                  title: this.$t("serverSettings.swal.message")
               })
            }).catch((error) => {
               if (error.response != undefined) {
                  if (error.response.status == 408) {
                     this.$store.dispatch('deslogar')

                     this.$toast.fire({
                        icon: 'warning',
                        title: this.$t("serverSettings.swal.expired")
                     });
                  } else {
                     this.$toast.fire({
                        icon: 'error',
                        title: 'Error: ' + error.response.status
                     });
                  }
               } else {
                  this.$toast.fire({
                     icon: 'error',
                     title: error
                  });
               }
            })
         }, 100);
      },
      testMessage : function (type, idChannel) {
         this.$store.dispatch('sendMessage', {'type': type, 'idServer': this.serverS.id_s, 'idChannel': idChannel}).then(() => {
            this.$toast.fire({
               icon: 'success',
               title: this.$t("serverSettings.messageSent")
            })
         }).catch((error) => {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  this.$store.dispatch('deslogar')

                  this.$toast.fire({
                     icon: 'warning',
                     title: this.$t("serverSettings.swal.expired")
                  });
               } else {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Error: ' + error.response.status
                  });
               }
            } else {
               this.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         })
      }
	},
	mounted() {
      this.$store.dispatch('getChannels')
		this.getSettings()
	}
}

</script>